/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCookies } from 'react-cookie'
import { FC } from 'react'
import { useMsal } from '@azure/msal-react';
import { getCookie } from 'react-use-cookie';
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { getUserByEmail } from '../../../../app/modules/auth/core/_requests'
import { removeMsalAuth } from '../../../../app/modules/authentication/components/AuthHelpers'
import { Languages } from './Languages'
import { toAbsoluteUrl, isUserOrganizational, getIsAspNetUser, logoutAspNetUser, getUserRoleCookie } from '../../../helpers'

const HeaderUserMenu: FC = () => {
    const [cookie, setCookie, removeCookie] = useCookies();
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate();
    const json = getUserRoleCookie()
    const userinfo = json ? JSON.parse(json) : {}
    //console.log('userinfo', userinfo)

    const { instance } = useMsal()
    const activeAccount = instance ? instance.getActiveAccount() : null
    //console.log("activeAccount", activeAccount)

    const handleLogoutPopup = async () => {
        if (getIsAspNetUser()) {
            logoutAspNetUser(removeCookie, navigate)

        } else {
            removeMsalAuth()
            instance
                .logoutPopup({
                    mainWindowRedirectUri: '/', // redirects the top level app after logout
                    account: instance.getActiveAccount(),
                })
                .catch((error) => {
                    console.log("logout.error", error)
                    navigate('/')
                    document.location.reload()
                })
        }
    };

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5 d-none' >
                        <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-5'>
                            {getIsAspNetUser() ? userinfo.username : activeAccount ? activeAccount.name : '-'}
                            <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2 d-none'>Pro</span>
                        </div>
                        <a href='#' className='fw-bold text-muted fs-6'>
                            {getIsAspNetUser() ? userinfo.email : activeAccount ? activeAccount.username : '-'}
                        </a>
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-7'>
                            {userinfo?.RoleName}
                        </div>
                        {isUserOrganizational() &&
                            <a href='#' className='fw-bold text-muted fs-7'>
                                {userinfo?.OrganizationName}
                            </a>
                        }
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-5 d-none'>
                <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
                    My Profile
                </Link>
            </div>

            <div className='menu-item px-5 d-none'>
                <a href='#' className='menu-link px-5'>
                    <span className='menu-text'>My Projects</span>
                    <span className='menu-badge'>
                        <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
                    </span>
                </a>
            </div>

            <div
                className='menu-item px-5'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='left-start'
                data-kt-menu-flip='bottom'
            >
                <a href='#' className='menu-link px-5 d-none'>
                    <span className='menu-title'>My Subscription</span>
                    <span className='menu-arrow'></span>
                </a>

                <div className='menu-sub menu-sub-dropdown w-175px py-4 d-none'>
                    <div className='menu-item px-3'>
                        <a href='#' className='menu-link px-5'>
                            Referrals
                        </a>
                    </div>

                    <div className='menu-item px-3'>
                        <a href='#' className='menu-link px-5'>
                            Billing
                        </a>
                    </div>

                    <div className='menu-item px-3'>
                        <a href='#' className='menu-link px-5'>
                            Payments
                        </a>
                    </div>

                    <div className='menu-item px-3'>
                        <a href='#' className='menu-link d-flex flex-stack px-5'>
                            Statements
                            <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='View your statements'
                            ></i>
                        </a>
                    </div>

                    <div className='separator my-2 d-none'></div>

                    <div className='menu-item px-3 d-none'>
                        <div className='menu-content px-3'>
                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input w-30px h-20px'
                                    type='checkbox'
                                    value='1'
                                    defaultChecked={true}
                                    name='notifications'
                                />
                                <span className='form-check-label text-muted fs-7'>Notifications</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='menu-item px-5 d-none'>
                <a href='#' className='menu-link px-5'>
                    My Statements
                </a>
            </div>

            <div className='separator my-2 d-none'></div>

            {/*<Languages />*/}

            <div className='menu-item px-5 my-1 d-none'>
                <Link to='/crafted/account/settings' className='menu-link px-5'>
                    Account Settings
                </Link>
            </div>

            {/*<div className='menu-item px-5'>*/}
            {/*    <a onClick={logout} className='menu-link px-5'>*/}
            {/*        Sign Out*/}
            {/*    </a>*/}
            {/*</div>*/}
            <div className='menu-item px-5'>
                <a onClick={handleLogoutPopup} className='menu-link px-5' href="#">
                    Sign Out
                </a>
            </div>

        </div>
    )
}

export { HeaderUserMenu }
