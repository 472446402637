import { getCookie } from 'react-use-cookie';
import { ROLE_ORGANIZATIONAL, ROLE_PROVIDER, ROLE_ADMIN } from '.';

const USER_COOKIE = 'userRole2'

const mockupUser = null
//const mockupUser = { userRowId: 'C7ECF226-A800-4DD9-9C01-9F8E409EC141', role: ROLE_ADMIN, organizationRowId: null }
//const mockupUser = { userRowId: '5558F739-3A93-4A8B-BF6A-C0EF53A18C78', role: ROLE_ORGANIZATIONAL, organizationRowId: '9999d854-c6b1-4485-ab27-1c70e7dc36b4' }
//const mockupUser = { userRowId: '0BB4A4B4-3592-4239-A4C2-E6DF93A9F0DB', role: ROLE_ORGANIZATIONAL, organizationRowId: '8356d5b2-21d6-410d-b465-bde70689699c' }


export const getUserRoleCookie = () => {
	const data = getCookie(USER_COOKIE)
	//console.log("getUserRoleCookie.data", data)
	if (data) {
		const json = atob(data)
		//console.log("getUserRoleCookie.json", json)
		return json
	} else {
		return null
	}
}

export const getUserRole = () => {
	//console.log('process.env.REACT_APP_IS_DEVELOPMENT', process.env.REACT_APP_IS_DEVELOPMENT)

	if (mockupUser && process.env.REACT_APP_IS_DEVELOPMENT == '1') return mockupUser.role

	const json = getUserRoleCookie()
	if (json) {
		const userinfo = JSON.parse(json)
		//console.log('userinfo', userinfo)

		if (userinfo?.IsUser === true) return ROLE_ORGANIZATIONAL
		else if (userinfo?.IsProvider === true) return ROLE_PROVIDER
		else return ROLE_ADMIN
	} else {
		return ROLE_ADMIN
	}
}

export const getUserOrganizationRowId = () => {
	if (mockupUser && process.env.REACT_APP_IS_DEVELOPMENT == '1') return mockupUser.organizationRowId

	const json = getUserRoleCookie()
	//console.log('json', json)
	if (json) {
		const userinfo = JSON.parse(json)
		return userinfo?.OrganizationRowId
	}

	return null
}

export const getUserRowId = () => {
	if (mockupUser && process.env.REACT_APP_IS_DEVELOPMENT == '1') return mockupUser.userRowId

	const json = getUserRoleCookie()
	//console.log('json', json)
	if (json) {
		const userinfo = JSON.parse(json)
		return userinfo?.UserRowId
	}

	return null
}

export const getUserParentOrganizationRowId = () => {
	const json = getUserRoleCookie()
	if (json) {
		const userinfo = JSON.parse(json)
		return userinfo?.ParentOrganizationRowId
	}

	return null
}

export const getUserIsChildOrganization = () => {
	const json = getUserRoleCookie()
	//console.log('json', json)
	if (json) {
		const userinfo = JSON.parse(json)
		if (userinfo?.IsChildOrganization === true) {
			return true
		}
	}

	return false
}


export const getIsAspNetUser = () => {
	if (!getEnvIsAllowAspnetUser()) {
		return false
	}

	const json = getUserRoleCookie()
	//console.log('getIsAspNetUser.json', json)
	if (json) {
		const userinfo = JSON.parse(json)
		//console.log('json3.userinfo', userinfo)
		if (userinfo?.IsAspNetUser === true) {
			return true
		}
	}

	return false
}

export const logoutAspNetUser = (removeCookie, navigate) => {
	//console.log("document.cookie.1", document.cookie)
	removeCookie(USER_COOKIE)
	//console.log("document.cookie.2", document.cookie)
	navigate('/auth2/login2')
	document.location.reload()
}



export const isUserAdmin = () => getUserRole() == ROLE_ADMIN
export const isUserOrganizational = () => getUserRole() == ROLE_ORGANIZATIONAL
export const isUserProvider = () => getUserRole() == ROLE_PROVIDER
export const isUserOrganizationalOrProvider = () => (getUserRole() == ROLE_ORGANIZATIONAL || getUserRole() == ROLE_PROVIDER)
export const isUserJTSAdmin = () => {
	const json = getUserRoleCookie()
	if (json) {
		const userinfo = JSON.parse(json)
		return userinfo.IsJTSAdmin
	}
	return false
}
export const isUserLogin = () => {
	const json = getUserRoleCookie()
	//console.log("json", json)
	if (json) {
		const userinfo = JSON.parse(json)
		return userinfo.isLogin
	}
	return false
}
export const userEmail = () => {
	const json = getUserRoleCookie()
	if (json) {
		const userinfo = JSON.parse(json)
		return userinfo.email
	}
	return ""
}

export const isRequireOrgInfoMode = () => isRequireOrgInfo()

export const isRequireOrgInfo = () => {
	const json = getUserRoleCookie()
	//console.log("json", json)
	if (json) {
		const userinfo = JSON.parse(json)
		return userinfo.IsRequireOrgInfo
	}
	return false
}


export const canEditOrganization = () => isUserAdmin() || isRequireOrgInfoMode()

export const isShowDashboard = () => (getUserRole() !== ROLE_PROVIDER)
export const isShowOrganizations = () => (getUserRole() !== ROLE_PROVIDER)

export const getEnvData = () => {
	const json = sessionStorage.getItem("envData")
	if (json) {
		const data = JSON.parse(json)
		return data
	} else {
		return null
	}
}

export const setEnvData = (data) => {
	if (data) {
		const json = JSON.stringify(data)
		sessionStorage.setItem("envData", json)
	}
}

export const getEnvReportsUrl = () => {
	const data = getEnvData()
	const url = data?.reportsUrl
	return url ? url : "#"
}

export const getEnvGeoSearchUrl = () => {
	const data = getEnvData()
	const url = data?.geoSearchUrl
	return url ? url : "#"
}

export const getEnvTimeoutSeconds = () => {
	const data = getEnvData()
	const seconds = data?.timeoutSeconds
	return seconds ? seconds : -1
}

export const getEnvTimeoutCountdownSeconds = () => {
	const data = getEnvData()
	const seconds = data?.timeoutCountdownSeconds
	return seconds ? seconds : -1
}

export const getEnvIsAllowAspnetUser = () => {
	const data = getEnvData()
	const isAllowAspnetUser = data?.isAllowAspnetUser
	return isAllowAspnetUser ? isAllowAspnetUser : false
}


const key_in_azure_login = "in_azure_login"

export const getInAzureLogin = () => {
	const data = sessionStorage.getItem(key_in_azure_login)
	return (data === "1")
}

export const setInAzureLogin = (value: boolean) => {
	sessionStorage.setItem(key_in_azure_login, value ? "1" : "0")
}
